<template>
    <uw-content title="组织管理" padding="10px">

        <!-- 菜单 -->
        <template slot="suffix">
            <el-button type="primary" size="mini" @click="$refs.organizeCreate.Open()">新增组织</el-button>
            <el-button type="primary" size="mini" @click="$refs.xTable.print()" >打印</el-button>
            <el-button type="primary" size="mini" @click="$refs.xTable.openExport()" >导出</el-button>
        </template>

        <uw-table :show-footer="false" :show-header="false">

            <!-- 表数据 -->
            <template slot="data">
                <vxe-table
                    ref="xTable"
                    :data="table"
                    :loading="loading"
                    :export-config="{}"
                    :print-config="{}"
                    :row-config="{keyField: 'id'}"
                    :tree-config="{transform: true, childrenField: 'children', rowField: 'id', parentField: 'parent', indent: 10, reserve: true, expandAll: true, showLine: true}"
                    height="100%" >

                    <!-- 组织/部门名称 -->
                    <vxe-column min-width="200" field="name" title="组织/部门名称" tree-node>
                        <template #default="{ row }">
                            <el-link :underline="false" type="primary" @click="$refs.organizeDrawer.Open(row.id)">{{ row.name }}</el-link>
                        </template>
                    </vxe-column>

                    <!-- 统计 -->
                    <vxe-column min-width="60" align="center" title="在职"><template #default="{ row }">{{ row.users_count['在职'] }}</template></vxe-column>
                    <vxe-column min-width="60" align="center" title="离职"><template #default="{ row }">{{ row.users_count['离职'] }}</template></vxe-column>
                    <vxe-column min-width="60" align="center" title="全职"><template #default="{ row }">{{ row.users_count['全职'] }}</template></vxe-column>
                    <vxe-column min-width="60" align="center" title="兼职"><template #default="{ row }">{{ row.users_count['兼职'] }}</template></vxe-column>
                    <vxe-column min-width="60" align="center" title="试用"><template #default="{ row }">{{ row.users_count['试用'] }}</template></vxe-column>
                    <vxe-column min-width="60" align="center" title="实习"><template #default="{ row }">{{ row.users_count['实习'] }}</template></vxe-column>
                    <vxe-column min-width="60" align="center" title="劳务"><template #default="{ row }">{{ row.users_count['劳务'] }}</template></vxe-column>
                    <vxe-column min-width="60" align="center" title="顾问"><template #default="{ row }">{{ row.users_count['顾问'] }}</template></vxe-column>
                    <vxe-column min-width="60" align="center" title="返聘"><template #default="{ row }">{{ row.users_count['返聘'] }}</template></vxe-column>
                    <vxe-column min-width="60" align="center" title="外包"><template #default="{ row }">{{ row.users_count['外包'] }}</template></vxe-column>

                    <!-- 操作 -->
                    <vxe-column width="160" title="操作" fixed="right" align="center">
                        <template #default="{ row }">
                            <el-link :underline="false" type="primary" :style="{ marginRight: '10px' }" @click="$refs.organizeCreate.Open({ parent: row.id })">新增</el-link>
                            <el-link :underline="false" type="primary" :style="{ marginRight: '10px' }" @click="$refs.organizeUpdate.Open(row)">编辑</el-link>
                            <el-link :underline="false" type="danger"  @click="OrganizeDelete(row)">删除</el-link>
                        </template>
                    </vxe-column>

                </vxe-table>
            </template>

        </uw-table>

        <!-- 组件 -->
        <hrm-organize-create ref="organizeCreate" @onChange="TableSearch()" />
        <hrm-organize-update ref="organizeUpdate" @onChange="TableSearch()" />
        <hrm-organize-drawer ref="organizeDrawer" @onChange="TableSearch()" />

    </uw-content>
</template>

<script>

export default {

    data () {
        return {
            table: [],
            loading: false,
            
            search: {
                organize_id: 0,
                name: null,
                phone: null
            }
         }
    },
     
    async mounted () {
        this.TableSearch()
    },

    methods: {

        /**
        |--------------------------------------------------
        | 表格数据 - 查询
        |--------------------------------------------------
        |
        */

        TableSearch() {
            this.$http.post('/9api/hrm/organize/search-all', { append: [ 'users_count' ] }).then(rps => {
                this.table = rps.data
            })
        },

        /**
         * 用户组织表 - 点击事件
         */
        OrganizeNodeClick(data) {
            this.search.organize_id = data.id
            this.UsersGet()
        },

        /**
         * 用户组织表 - 删除组织
         */
        OrganizeDelete(data, node) {
            this.$confirm('一旦删除将无法恢复, 是否要删除?', '警告', {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                type: 'error'
            }).then(() => {
                this.$http.post('/9api/hrm/organize/delete', { id: data.id }).then((rps) => {
                    this.OrganizeGet()
                })
            })
        },

        /**
         * 用户表 - 批量更新企业微信
         */
        UpdateWecom(id) {
            this.$http.post('/9api/users/update-wecom', {id: id}).then(rps => {
                // 提交成功
                if (rps.data.status == 'success' ) {

                    // 提示用户
                    this.$notify.success({
                        title: '更新成功',
                        message: rps.data.success,
                        duration: 2000
                    })

                    this.organizeCascader.value = null
                    console.log(this.$refs.organizeCascader)
                    this.$refs.organizeCascader.dropDownVisible = false
                    this.UsersGet()
                }
            })
        }
     }
 }
</script>